import { useState } from "react";
import { signIn } from "next-auth/client";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { ILogin } from "@/types/auth";
import Head from "next/head";

const Login = () => {
  const router = useRouter();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILogin>();

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const handleLogin = handleSubmit((data: ILogin) => {
    setLoading(true);
    (async () => {
      const response = await signIn("credentials", {
        username: data.username,
        password: data.password,
        redirect: false,
      });
      if (response?.error === null) {
        router.push("/displays");
      } else {
        setErrorMessage("Invalid username or password.");
      }

      setLoading(false);
    })();
  });

  return (
    <div className='min-h-screen flex items-center justify-center bg-secondaryBlack py-12 px-4 sm:px-6 lg:px-8'>
      <Head>
        <title>Canvas&trade;</title>
        <meta name='description' content='RibbonCMS' />
        {/* <link rel='icon' href='/favicon.ico' /> */}
      </Head>
      <div className='absolute top-1 right-3 text-white'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1788 637.244'
          className='w-12 h-12'>
          <g id='Swoosh_-_White' data-name='Swoosh - White'>
            <path
              fill='#ffffff'
              d='M263.231,699.362q-79.908-3.169-130.946-43.767c-6.5-5.169-22-20.69-27.2-27.227C91.261,610.99,81.872,594.083,75.6,575.309c-19.283-57.766-9.351-133.563,28.383-216.761C136.3,287.34,186.187,216.707,273.19,118.88c12.817-14.4,50.989-56.5,51.23-56.5.082,0-1.982,3.6-4.6,8-22.628,37.9-41.988,82.53-52.533,121.173-16.94,62-14.892,115.2,5.984,156.475,14.41,28.421,39.1,53.042,66.872,66.659,48.618,23.816,119.782,25.8,206.709,5.77,5.984-1.39,302.521-80.11,658.966-174.932C1562.27,150.684,1853.967,73.142,1854,73.207c.1.094-828.133,354.5-1258.08,538.358-68.1,29.11-86.308,36.469-118.31,47.7-81.834,28.738-155.134,42.459-214.379,40.1'
              transform='translate(-66 -62.378)'
            />
          </g>
        </svg>
      </div>
      <div className='max-w-md w-full bg-white p-10'>
        <div>
          {errorMessage && (
            <p className='bg-red-200 border-red-900 text-red-900 border border rounded p-3'>
              {errorMessage}
            </p>
          )}
          <form className='mt-8 space-y-6' onSubmit={handleLogin}>
            <div className='space-y-2'>
              <div>
                <label htmlFor='email-address' className='sr-only'>
                  Username
                </label>
                <input
                  id='username'
                  type='text'
                  {...register("username", { required: true })}
                  className='appearance-none rounded-none relative block w-full p-5 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-secondaryBlack focus:border-secondaryBlack bg-gray-100 focus:z-10 sm:text-sm'
                  placeholder='Username'
                />
                {errors.username && (
                  <p className='py-3 text-red-400'>This field is required.</p>
                )}
              </div>
              <div>
                <label htmlFor='password' className='sr-only'>
                  Password
                </label>
                <input
                  id='password'
                  type='password'
                  className='appearance-none rounded-none relative block w-full p-5 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-secondaryBlack focus:border-secondaryBlack bg-gray-100 focus:z-10 sm:text-sm'
                  placeholder='Password'
                  {...register("password", { required: true })}
                />
                {errors.password && (
                  <p className='pt-3 text-red-400'>This field is required.</p>
                )}
              </div>
              <div className='flex justify-end pt-5'>
                <a
                  href='mailto:help@dgtlworkshop.com?subject=Forgot password'
                  className='appearance-none pt-3 pb-3 pl-5 pr-5'>
                  Forget Password
                </a>
                <button
                  type='submit'
                  className='appearance-none bg-black text-white rounded-full pt-3 pb-3 pl-5 pr-5'
                  disabled={loading ?? "disabled"}>
                  {!loading && "Login"}
                  {loading && "Logging in..."}
                </button>
              </div>
            </div>
          </form>

          <p className='text-sm flex justify-center mt-10'>
            Powered by Canvas&trade;.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Login;
